import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom';
import Rooms from './components/Rooms';
import Start from './components/Start';

import {
  MsalProvider,
  useMsal,
  MsalAuthenticationTemplate,
} from '@azure/msal-react';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';

function App() {
  const { accounts } = useMsal();
  const account = accounts.length > 0 ? accounts[0] : undefined;

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Start account={account} />} />
          <Route path="/:id" element={<Rooms />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

// MSAL configuration
const configuration = {
  auth: {
    authority: process.env.REACT_APP_AUTHORITY ?? '',
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

const pca = new PublicClientApplication(configuration);

const AppProvider = () => {
  const authRequest = {
    scopes: ['openid'],
  };

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        authenticationRequest={authRequest}
        interactionType={InteractionType.Redirect}
      >
        <App />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

export default AppProvider;
