import { Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

export default function UnBookableRooms() {
  const [phonebooth, setPhoneBooth] = useState('');
  const [opencollab, setOpenCollab] = useState('');
  const [focus, setFocus] = useState('');
  const [livingroom, setLivingRoom] = useState('');
  const [standup, setStandUp] = useState('');

  /*  const [colors, setColors] = useState({
    phonebooth: '',
    opencollab: '',
    focus: '',
    livingroom: '',
    standup: '',
  }); */

  const phoneHover = {
    fill: `${phonebooth}`,
    fillOpacity: '80%',
  };
  const collabHover = {
    fill: `${opencollab}`,
    fillOpacity: '100%',
  };
  const focusHover = {
    fill: `${focus}`,
    fillOpacity: '80%',
  };
  const livingRoomHover = {
    fill: `${livingroom}`,
    fillOpacity: '100%',
  };
  const standUpHover = {
    fill: `${standup}`,
    fillOpacity: '80%',
  };

  return (
    <>
      <rect
        onMouseEnter={() => setPhoneBooth('#A4998D')}
        onMouseLeave={() => setPhoneBooth('')}
        className="phone-booths"
        x="211"
        y="387"
        width="171"
        height="16"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        onMouseEnter={() => setStandUp('#f7e8cb')}
        onMouseLeave={() => setStandUp('')}
        className="standup"
        x="211"
        y="361"
        width="171"
        height="16"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        className=""
        x="211"
        y="335"
        width="171"
        height="16"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        onMouseEnter={() => setLivingRoom('#9bc5bb')}
        onMouseLeave={() => setLivingRoom('')}
        className=""
        x="211"
        y="309"
        width="171"
        height="16"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        onMouseEnter={() => setOpenCollab('#f3c99f')}
        onMouseLeave={() => setOpenCollab('')}
        className="open-collab"
        x="211"
        y="283"
        width="171"
        height="16"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        onMouseEnter={() => setFocus('#f8df9c')}
        onMouseLeave={() => setFocus('')}
        className="focus-area"
        x="211"
        y="257"
        width="171"
        height="16"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />

      <rect
        x="211"
        y="231"
        width="171"
        height="16"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        style={phoneHover}
        id="phoneBoothOne"
        x="541"
        y="336"
        width="22"
        height="28"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        style={phoneHover}
        id="phoneBoothTwo"
        x="774"
        y="335"
        width="35"
        height="43"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        style={phoneHover}
        id="phoneBoothThree"
        x="770"
        y="658"
        width="39"
        height="24"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        style={phoneHover}
        id="phoneBoothFour"
        x="569"
        y="658"
        width="22"
        height="24"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        style={standUpHover}
        id="standUpArea"
        x="543"
        y="506"
        width="111"
        height="43"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <path
        style={livingRoomHover}
        id="livingRoom"
        d="M541 504V366H565V336H654V504H541Z"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />

      <path
        style={collabHover}
        id="openCollabOne"
        d="M656.5 570.5V335H772V380H809.5V656H768V682H593V656H569V570.5H656.5Z"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        style={collabHover}
        id="openCollabTwo"
        x="960"
        y="566"
        width="33"
        height="110"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <path
        style={collabHover}
        id="openCollabThree"
        d="M950 799H837V760H860V712H837V696H950V799Z"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        style={focusHover}
        id="focusAreaOne"
        className="focusArea"
        x="899"
        y="226"
        width="114"
        height="66"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />

      <rect
        style={focusHover}
        id="focusAreaTwo"
        className="focusArea"
        x="975"
        y="530"
        width="12"
        height="13"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <rect
        style={focusHover}
        id="focusAreaThree"
        className="focusArea"
        x="837"
        y="714"
        width="21"
        height="44"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <Tooltip
        sx={{ fontSize: '16px' }}
        placement="top"
        title={
          <>
            <Typography sx={{ fontSize: '16px' }}>Main Stairs</Typography>
          </>
        }
        arrow
      >
        <rect
          id="main-stairs"
          x="837"
          y="588"
          width="113"
          height="104"
          fill="#C4C4C4"
          fillOpacity="0.01"
        />
      </Tooltip>
    </>
  );
}
