import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

function Start(account) {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  //save email for email from account
  const email = account.account.username.toLowerCase();
  console.log(email);

  const fetchUser = async () => {
    const usersFetch = await axios.get(
      process.env.REACT_APP_API_URL + `/users`
    );
    setUsers(usersFetch.data);
  };
  useEffect(() => {
    fetchUser();
  }, []);
  console.log(users);

  const findUser = (user) => {
    return user.email === email;
  };

  useEffect(() => {
    setUser(users.find(findUser));
    // eslint-disable-next-line
  }, [users]);

  return (
    <StartContainer>
      <StartBox>
        <BtnBox>
          <StartBtn onClick={() => navigate(`/${user.id}`)}>
            Visual Booking
          </StartBtn>
        </BtnBox>
      </StartBox>
    </StartContainer>
  );
}

const StartContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 90vh;
  margin: auto;
`;

const StartBox = styled.div`
  width: 40vw;
  height: 80vh;
  margin: 0 auto;
  border: 2px solid #f0f0f0;
  border-radius: 2rem;
`;

const BtnBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartBtn = styled.button`
  padding: 1rem 2rem;
  border-radius: 2rem;
  border: none;
  outline: none;
  background-color: #0158a3;
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
`;

export default Start;
