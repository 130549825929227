import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import image from '../assets/newMapSmall6-hd.png';
import {
  Modal,
  Box,
  Typography,
  Snackbar,
  Button,
  IconButton,
} from '@mui/material/';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnBookableRooms from './UnBookableRooms';

function Rooms() {
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [user, setUser] = useState({});
  const { id } = useParams();
  const [roomInfo, setRoomInfo] = useState({});
  const [roomId, setRoomId] = useState('');
  const [updatedRoom, setUpdatedRoom] = useState(false);

  const fetchRooms = async () => {
    const roomsFetch = await axios.get(
      process.env.REACT_APP_API_URL + '/rooms'
    );
    setRooms(roomsFetch.data);
  };
  const fetchUser = async () => {
    const usersFetch = await axios.get(
      process.env.REACT_APP_API_URL + `/users/${id}`
    );
    console.log(usersFetch.data);
    setUser(usersFetch.data);
  };
  useEffect(() => {
    fetchRooms();
    fetchUser();
    // eslint-disable-next-line
  }, []);

  function checkForMatch(array, propertyToMatch, valueToMatch) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][propertyToMatch] === valueToMatch) setRoomInfo(array[i]);
    }
    return true;
  }

  const handleOpen = (e) => {
    setOpen(true);
    setRoomId(e.target.id);
    checkForMatch(rooms, 'id', e.target.id);
  };

  const handleClick = () => {
    setOpenSnackbar(true);
    bookSeat();
  };

  const bookSeat = async () => {
    const response = await fetch(`http://localhost:8080/rooms/${roomId}`, {
      method: 'PATCH',
    });
    const data = await response.json();
    if (response.status === 200) {
      console.log('rum infon:', roomInfo.freeSeats);
      console.log('data:', data);
      setUpdatedRoom(true);
    }
  };
  //useEffect
  useEffect(() => {
    fetchRooms();
    setUpdatedRoom(false);
  }, [updatedRoom]);

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const handleClose = () => {
    //closes snackbar and modal at same time
    setOpen(false);
    setOpenSnackbar(false);
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  return (
    <div className="App">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <RoomContainer>
            <Typography
              fontSize={24}
              fontWeight={600}
              mb={2}
              align="center"
              variant="h2"
            >
              Hej {user?.user?.firstName}
            </Typography>
          </RoomContainer>
          <Typography
            textAlign={'center'}
            mb={1}
            variant="h6"
            fontWeight={600}
            fontSize={18}
          >
            Booking ({roomInfo.title})
          </Typography>
          <SeatsAndDate>
            <Seats>
              <PersonIcon sx={{ marginRight: '5px', fontSize: '18px' }} />
              <Typography variant="subtitle1">
                {roomInfo.freeSeats} / {roomInfo.seats}
              </Typography>
            </Seats>
            <Typography>{date}</Typography>
          </SeatsAndDate>
          <Typography variant="subtitle1">Your Details:</Typography>
          <div className="details-container">
            <div className="detail-titles">
              <Typography paddingRight={0.5}>User: </Typography>
              <Typography paddingRight={0.5}>Company: </Typography>
              <Typography paddingRight={0.5}>Department: </Typography>
            </div>
            <div className="details">
              <Typography fontWeight={600}>{user?.user?.email}</Typography>
              <Typography fontWeight={600}>{user?.user?.company}</Typography>
              <Typography fontWeight={600}>{user?.user?.department}</Typography>
            </div>
          </div>

          <Accordion elevation={0} sx={{ marginTop: 2, marginBottom: 5 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Favourite Colleagues</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {user?.favoriteColleagues?.map((colleague) => (
                <div key={colleague.email} className="tooltip-favs-accordian">
                  <FavoriteIcon
                    sx={{ fontSize: '14px', paddingRight: '4px' }}
                  />
                  <Typography variant="subtitle2" fontWeight={600}>
                    {colleague?.name}
                  </Typography>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
          <ButtonContainer>
            <StyledButton onClick={handleClose}>Cancel</StyledButton>
            <StyledButtonBook onClick={handleClick}>Book</StyledButtonBook>
          </ButtonContainer>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Seat booked"
        action={action}
      />

      <div className="container">
        <div className="welcome-section">
          <h1 className="welcome-title">
            Hej {user?.user?.firstName}! Welcome to Hubhult Floor 2 House A{' '}
          </h1>
        </div>
        <div className="parent">
          <img src={image} alt="test" />
          <div>
            <div className="svgContainer">
              <svg
                width="1440"
                height="1024"
                viewBox="0 0 1440 1024"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {rooms.map((room) => (
                  <React.Fragment key={room.id}>
                    {room.y ? (
                      <Tooltip
                        placement={room.placement}
                        title={
                          <>
                            <Typography>{room.title}</Typography>
                            <div className="tooltip">
                              <PersonIcon
                                sx={{ fontSize: '16px', paddingRight: '2px' }}
                              />
                              <Typography>
                                {`${room.freeSeats} / ${room.seats}`}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                              }}
                            >
                              <Typography>favourite colleagues</Typography>
                            </div>
                            {user?.favoriteColleagues?.map((colleague) => (
                              <div
                                key={colleague.email}
                                className="tooltip-favs"
                              >
                                <FavoriteIcon
                                  sx={{ fontSize: '14px', paddingRight: '2px' }}
                                />
                                <Typography>{colleague.name}</Typography>
                              </div>
                            ))}
                          </>
                        }
                        arrow
                      >
                        <rect
                          className={
                            user?.user?.department === room.title
                              ? 'active'
                              : ''
                          }
                          onClick={handleOpen}
                          id={room.id}
                          x={room.x}
                          y={room.y}
                          width={room.width}
                          height={room.height}
                          fill="#C4C4C4"
                          fillOpacity="0.01"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        placement={room.placement}
                        title={
                          <>
                            <Typography>{room.title}</Typography>
                            <div className="tooltip">
                              <PersonIcon
                                sx={{ fontSize: '16px', paddingRight: '2px' }}
                              />
                              <Typography>
                                {`${room.freeSeats} / ${room.seats}`}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                              }}
                            >
                              <Typography>favourite colleagues</Typography>
                            </div>
                            {user?.favoriteColleagues?.map((colleague) => (
                              <div
                                key={colleague.email}
                                className="tooltip-favs"
                              >
                                <FavoriteIcon
                                  sx={{ fontSize: '14px', paddingRight: '2px' }}
                                />
                                <Typography>{colleague.name}</Typography>
                              </div>
                            ))}
                          </>
                        }
                        arrow
                      >
                        <path
                          className={
                            user?.user?.department === room.title
                              ? 'active'
                              : ''
                          }
                          onClick={handleOpen}
                          id={room.id}
                          d={room.d}
                          fill="#C4C4C4"
                          fillOpacity="0.01"
                        />
                      </Tooltip>
                    )}
                  </React.Fragment>
                ))}
                {/* render the color codes and unbookable rooms */}

                <UnBookableRooms />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 'none',
  color: '#3b3b3b',
};

const RoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0rem;
`;

const SeatsAndDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`;

const Seats = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  width: 14rem;
  padding: 0.8rem 4.8rem;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: 500;
  color: #111111;
  background: none;
  border: 1px solid #111111;
  border-radius: 1.5rem;
  cursor: pointer;
  outline: none;
  margin-right: 1rem;
`;
const StyledButtonBook = styled.button`
  width: 14rem;
  padding: 0.9rem 4.8rem;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: 500;
  color: white;
  background-color: #0058a3;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
  outline: none;
`;

export default Rooms;
